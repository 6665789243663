import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { addToCart } from '@artemis/store/cart/slice';
import usePersistedQuery from '@artemis/utils/query/usePersistedQuery';
import { formatCurrency } from '@artemis/utils/currency-format';

import 'react-loading-skeleton/dist/skeleton.css';
import 'swiper/css';
import 'swiper/css/pagination';

const Container = styled.div`
  overflow: hidden;
`;

export const SectionHeading = styled.h2`
  ${props => props.theme.typography.h6};
  box-sizing: border-box;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-bottom: 10px;
  padding: 0 16px;
  ${props => props.theme.isTablet`
    margin: 0 auto 32px;
    max-width: 1236px;
    padding: 0 32px;
  `}
`;

const ItemsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: 1236px;
  margin: 0 auto;
  width: 100%;
`;

const SwiperContainer = styled.div`
  box-sizing: border-box;
  max-width: 1236px;
  margin: 0 auto;
  padding: 0 0 16px;
  position: relative;
  width: 100%;

  ${props => props.theme.isTablet`
    padding: 0 32px 8px;
  `}

  .swiper-pagination {
    position: static;
    min-height: 24px;
  }
  .swiper-pagination-lock {
    display: block;
  }
  .swiper-pagination-bullet {
    background: ${({ theme }) => theme.rtColors.black200};
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.rtColors.black800};
  }
`;

const ScrollableList = styled.div`
  width: 100%;
  display: flex;
  padding: 0 16px 16px;
  overflow: hidden;
  ${({ theme }) => theme.isTablet`
    padding: 0 32px 32px;
  `}
  overflow: auto;
  ${({ theme }) => theme.noScrollbars};
`;

const Item = styled.button`
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin: 0 20px 0 0;
  padding: 0;
  position: relative;
  width: 160px;
  :last-child {
    margin: 0;
  }
`;

const SkeletonItem = styled.div`
  flex: 0 0 auto;
  margin: 0 20px 0 0;
  padding: 0;
  width: 160px;
  :last-child {
    margin: 0;
  }
`;

const Image = styled.img`
  border-radius: 12px;
  box-shadow: ${props => props.theme.shadows.shadow6};
  width: 160px;
  height: 100px;
  object-fit: cover;
`;

const Chip = styled.span`
  ${({ theme }) => theme.typography.caption};
  background-color: ${({ theme }) => theme.rtColors.black800};
  color: ${({ theme }) => theme.rtColors.white};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  padding: 8px 10px;
  position: absolute;
  border-radius: 6px;
  left: 16px;
  top: 50px;
`;

const ItemName = styled.div`
  ${({ theme }) => theme.typography.overflowEllipsis};
  ${({ theme }) => theme.typography.bodySmall};
  ${({ theme }) => theme.isTablet`
    ${theme.typography.body};
    font-weight: ${theme.typography.fontWeightMedium};
  `};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  text-align: left;
`;
const ItemMerchantName = styled.div`
  ${({ theme }) => theme.typography.overflowEllipsis};
  ${({ theme }) => theme.typography.bodySmall};
  ${({ theme }) => theme.isTablet`
    ${theme.typography.body};
  `};
  text-align: left;
`;
const ItemMerchantIntersection = styled.div`
  ${({ theme }) => theme.typography.overflowEllipsis};
  ${({ theme }) => theme.typography.bodySmall};
  color: ${({ theme }) => theme.rtColors.black900};
  text-align: left;
`;

const ScrollOverlay = styled.div`
  display: none;
  height: 100px;
  position: absolute;
  transition: right 0.3s ease-in-out;
  z-index: 1;
  ${props => props.theme.isTablet`
    display: flex;
    align-items: center;
  `};

  &.swiper-button-disabled {
    display: none;
  }
`;

const ScrollOverlayButton = styled.div`
  background: none;
  border-radius: 50%;
  border-color: white;
  cursor: pointer;
  height: 32px;
  width: 32px;
`;

const ScrollOverlayReverseButton = styled(ScrollOverlayButton)`
  transform: rotate(180deg);
`;

const sharedPropTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

const GoLeft = ({ className, style }) => (
  <ScrollOverlay className={className} style={style}>
    <ScrollOverlayReverseButton>
      <ResponsiveImage
        id="icons.chevronRightCircle.img"
        specificAlt="Previous"
      />
    </ScrollOverlayReverseButton>
  </ScrollOverlay>
);

GoLeft.propTypes = sharedPropTypes;

const GoRight = ({ className, style }) => (
  <ScrollOverlay style={style} className={className}>
    <ScrollOverlayButton>
      <ResponsiveImage id="icons.chevronRightCircle.img" specificAlt="Next" />
    </ScrollOverlayButton>
  </ScrollOverlay>
);

GoRight.propTypes = sharedPropTypes;

const QuickReorderProps = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  isLoading: PropTypes.bool,
  navigateToCheckout: PropTypes.bool,
};

export const QuickReorder = ({
  className,
  items,
  isLoading,
  navigateToCheckout,
}) => {
  const dispatch = useDispatch();
  const persistedQuery = usePersistedQuery();

  const handleOnClick = item => {
    if (item.merchant.isAvailableNow) {
      dispatch(
        addToCart({
          addMenuItemSource: 'QUICK_REORDER',
          merchantId: item.merchant.id,
          item: {
            menuItemId: item.menuItem.id,
            quantity: 1,
            note: '',
            cartItemOptions: [],
            title: item.menuItem.title,
            priceMicro: item.menuItem.priceMicro,
          },
          navigateToUrl: navigateToCheckout,
          url: `${process.env.RT_BROWSER_ORIGIN}/order${item.merchant.menuPath}/checkout${persistedQuery}`,
        }),
      );
    } else {
      window.location = `${process.env.RT_BROWSER_ORIGIN}/order${item.merchant.menuPath}${persistedQuery}&item=${item.menuItem.id}`;
    }
  };

  if (!isLoading && items.length === 0) {
    return <></>;
  }

  return (
    <Container className={className}>
      <SectionHeading>
        {isLoading ? (
          <Skeleton height={21} width={158} />
        ) : (
          <FormattedMessage entry="Quick Re-order" />
        )}
      </SectionHeading>
      {isLoading ? (
        <ItemsContainer>
          <ScrollableList>
            {[4, 0, 2, 3, 5, 1].map(i => (
              <SkeletonItem key={i}>
                <Skeleton height={100} width={160} />
                <ItemName>
                  <Skeleton width={i * 15 + 80} />
                </ItemName>
                <ItemMerchantName>
                  <Skeleton />
                </ItemMerchantName>
                <ItemMerchantIntersection>
                  <Skeleton width={i * 10 + 60} />
                </ItemMerchantIntersection>
              </SkeletonItem>
            ))}
          </ScrollableList>
        </ItemsContainer>
      ) : (
        <SwiperContainer>
          <GoLeft
            className="swiper-prev"
            style={{ top: 0, left: '16px', zIndex: 10 }}
          />
          <Swiper
            spaceBetween={20}
            slidesPerView="auto"
            slidesPerGroupAuto
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: '.swiper-next',
              prevEl: '.swiper-prev',
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              767: {
                slidesOffsetAfter: 0,
                slidesOffsetBefore: 0,
              },
            }}
            slidesOffsetAfter={16}
            slidesOffsetBefore={16}
          >
            {items.map(item => (
              <SwiperSlide style={{ width: 'auto' }}>
                <Item
                  key={item.menuItem.id}
                  onClick={() => handleOnClick(item)}
                >
                  <Image
                    src={item.menuItem.imageUrl}
                    alt={item.menuItem.title}
                  />
                  <Chip>
                    {formatCurrency({
                      value: item.menuItem.priceMicro,
                      isMicro: true,
                      currencyCode: item.menuItem.currency,
                    })}
                  </Chip>
                  <ItemName>{item.menuItem.title}</ItemName>
                  <ItemMerchantName>{item.merchant.name}</ItemMerchantName>
                  <ItemMerchantIntersection style={{ position: 'static' }}>
                    {item.merchant.intersection}
                  </ItemMerchantIntersection>
                </Item>
              </SwiperSlide>
            ))}
          </Swiper>
          <GoRight
            className="swiper-next"
            style={{
              top: 0,
              right: '16px',
              zIndex: 10,
            }}
          />
        </SwiperContainer>
      )}
    </Container>
  );
};

QuickReorder.propTypes = QuickReorderProps;
